import SmoothScroll from "smooth-scroll";

const ScrollSmooth = () => {

	const rootStyles = getComputedStyle(document.documentElement);
	const navFixed = rootStyles.getPropertyValue('--nav-fixed').trim().replace('rem', '');

	const OffSet = (parseFloat(navFixed) * 16);

	const scroll = new SmoothScroll('a[href*="#"][js-custom-smooth]', {
		speed: 260,
		offset: OffSet,
		easing: 'easeInOutCubic'
	});
}

export default ScrollSmooth;
import axios from "axios";
import { find, findAll } from "../utils/dom";


const PageAcervo = () => {
	const page = find(`[js-page='acervo_digital']`);
	if (!page) return;

	const BASEURL = `${window.location.protocol}//${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}`;


	/**
	 * Função que dado o pathname do navegador, converter os elementos passados na url em um objeto.
	 * 
	 * @param {string} filtros 
	 * @returns  
	 */
	function splitFilters(filtros) {
		if (!filtros) return;

		const splitFiltros = filtros.replace("?", "").split("&");
		const filtrosObj = {};
		splitFiltros.forEach((filtro) => {
			const splitFiltro = filtro.split("=");

			if (!filtrosObj[splitFiltro[0]]) {
				filtrosObj[splitFiltro[0]] = [];
			}
			filtrosObj[splitFiltro[0]].push(splitFiltro[1]);
		});

		return filtrosObj;
	}


	function generateStringFilterFromObject(obj) {

		let stringGerada = '';
		console.log(obj)
		Object.entries(obj).forEach(([key, value]) => {

			if (Array.isArray(value)) {
				value.forEach((item) => {
					stringGerada += `&${key}=${item}`;
				})
			} else {
				stringGerada += `&${key}=${value}`;
			}
		})

		return `?${stringGerada.slice(1)}`;
	}



	function removeItemFilter(objeto, objeto_elemento, elemento) {
		const index_item_remover = objeto?.[objeto_elemento]?.indexOf(elemento);

		if (index_item_remover != -1) {
			objeto?.[objeto_elemento]?.splice(index_item_remover, 1);
		}
	}

	function addItemFilter(objeto, objeto_elemento, elemento) {

		if (!objeto[objeto_elemento]) {
			objeto[objeto_elemento] = [];
		}

		const is_exist_element = objeto[objeto_elemento].find((e) => {
			return (e == elemento)
		})
		if (!is_exist_element) {
			objeto[objeto_elemento].push(elemento)
		}
	}

	function is_checked() {
		const itensFiltros = findAll('[js-filtro]');
		if (!itensFiltros) return;

		const url = window.location.href;

		itensFiltros.forEach((item) => {
			const busca = `${item.getAttribute('js-filtro')}=${item.value}`
			if (url.includes(busca)) {
				item.checked = true;
			}
		})

	}

	function handleFiltro() {
		const itensFiltros = findAll('[js-filtro]');
		if (!itensFiltros) return;


		let objetoFiltro = splitFilters(window.location.search);
		if (!objetoFiltro) objetoFiltro = {}

		itensFiltros?.forEach((item) => {
			item.addEventListener("click", (e) => {
				const value = e.target.value;
				const alvo = e.target.getAttribute('js-filtro');

				if (e.target.checked) {
					addItemFilter(objetoFiltro, alvo, value);
					const result = generateStringFilterFromObject(objetoFiltro);
					window.location.href = `${BASEURL}/obras/${result}`;
				} else {
					removeItemFilter(objetoFiltro, alvo, value);
					const result = generateStringFilterFromObject(objetoFiltro);
					window.location.href = `${BASEURL}/obras/${result}`;
				}

			})
		})
	}


	function handleDropdows() {
		const dropdows = findAll("[js-dropdown]");
		if (!dropdows) return;


		dropdows.forEach((drop) => {
			drop.addEventListener("click", (e) => {
				const dropId = e.target.getAttribute('dropdown');
				const conteudoDrop = find(`[content-dropdown='${dropId}']`);
				const IconeDrop = e.target.querySelector(".icone_open");

				conteudoDrop?.classList.toggle("is_open");
				if (conteudoDrop?.classList.contains("is_open")) {
					IconeDrop?.classList.add("is_open");
				} else {
					IconeDrop?.classList.remove("is_open");
				}
			})
		})
	}

	handleDropdows();
	is_checked();
	handleFiltro();



	/*
	
		function makerCard(dado) {
			const id = dado.id;
			const url = `${BASEURL}/acervo/${dado.id}`;
			const img_url = (dado.imagem_principal) ? dado.imagem_principal : '';
			const titulo = dado.titulo
			const texto_antes_ano = (dado.texto_antes_ano) ? dado.texto_antes_ano : '';
			const ano = (dado.ano) ? dado.ano : '';
	
	
			return `
				<a href="${url}" class="card-acervo-digital">
					<figure>
						<img src="${img_url}" alt="" />
					</figure>
					<p>
						${titulo}, ${texto_antes_ano} ${ano}
					</p>
				</a>
			`;
		}
	
	
		async function loadAPIObras(extraString = '', is_resed = false) {
	
			try {
				const arrayResult = await axios.get(`${BASEURL}/api/obras${extraString}`).then(result => {
					return result.data.results
				});
	
	
				if (is_resed) {
					find(`[js-content-obra-grid="0"]`).innerHTML = ''
					find(`[js-content-obra-grid="1"]`).innerHTML = ''
					find(`[js-content-obra-grid="2"]`).innerHTML = ''
				}
	
				arrayResult?.forEach((result, index) => {
					const container = find(`[js-content-obra-grid="${index % 3}"]`);
	
					container.insertAdjacentHTML("beforeend", makerCard(result));
				});
	
			} catch (error) {
				console.log(error)
			}
		}
	
	
		function resetCheckboxClasse() {
			const checkbox = findAll(`[js-filtro='classe']:checked`);
			checkbox?.forEach((item) => {
				item.checked = false;
			})
		}
	
		function resetCheckboxPeriodo() {
			const checkbox = findAll(`[js-filtro='periodo']:checked`);
			checkbox?.forEach((item) => {
				item.checked = false;
			})
		}
	
	
		function handleFiltroClasse() {
			const filtros = findAll('[js-filtro]');
			if (!filtros) return;
	
			filtros.forEach((item) => {
				item.addEventListener("click", async (e) => {
	
	
					let is_resed = 0;
					if (!e.target.checked && e.target.getAttribute('js-filtro') == 'classe') {
						resetCheckboxClasse()
						loadAPIObras('', true);
						//return
					} else if (e.target.getAttribute('js-filtro') == 'classe') {
						is_resed = 1;
					}
	
	
					if (!e.target.checked && e.target.getAttribute('js-filtro') == 'periodo') {
						resetCheckboxPeriodo()
						loadAPIObras('', true);
						//return
					} else if (e.target.getAttribute('js-filtro') == 'periodo') {
						is_resed = 2;
					}
	
					switch (is_resed) {
						case 1:
							resetCheckboxClasse()
							e.target.checked = true;
							break;
						case 2:
							resetCheckboxPeriodo()
							e.target.checked = true;
							break;
					}
	
					const filtroClasse = findAll(`[js-filtro="classe"]`).find((value) => {
						return value.checked == true;
					})
	
					const filtroPeriodo = findAll(`[js-filtro="periodo"]`).find((value) => {
						return value.checked == true;
					})
	
	
	
					let stringPesquisa = '';
					if (filtroClasse && filtroPeriodo) {
						const ano = filtroPeriodo.value.split('-');
						stringPesquisa = `?classe=${filtroClasse.value}&ano_inicio=${ano[0]}&ano_final=${ano[1]}`;
						console.log(`Periodo: ${filtroPeriodo}   ||   Classe: ${filtroClasse}`)
	
					} else if (filtroClasse) {
						stringPesquisa = `?classe=${filtroClasse.value}`;
						console.log(`Periodo: ${filtroPeriodo}   ||   Classe: ${filtroClasse}`)
	
					} else if (filtroPeriodo) {
						const ano = filtroPeriodo.value.split('-');
						stringPesquisa = `?ano_inicio=${ano[0]}&ano_final=${ano[1]}`;
						console.log(`Periodo: ${filtroPeriodo}   ||   Classe: ${filtroClasse}`)
					}
	
	
					loadAPIObras(stringPesquisa, true);
	
				})
			})
		}
	
	
		loadAPIObras();
	
		handleFiltroClasse();*/

}

export default PageAcervo;
import axios from 'axios'
import axiosConfig from './modules/axiosConfig'
import PageHome from './pages/Home.js'
import PageAArtista from './pages/A_Artista'
import PageInstituto from './pages/Instituto'
import PageAcervo from './pages/Acervo'
import PageSingleAcervo from './pages/Single_Acervo'
import PageContato from './pages/Contato'
import PageApoio from './pages/Apoio'

//import PageDsSections from './pages/ds_sections.js'
import PageLogin from './pages/Login'
import UseMouseOrKeyboard from './modules/UseMouseOrKeyboard'
import DesignSystem from './DesignSystem/DesignSystem'
import { register } from 'swiper/element/bundle'
import logs from './utils/logs'
import ripple from './modules/ripple'
import formErros from './modules/formErros'
import animaAoScroll from './animation/animaAoScroll'
import animateSetup from './animation/animateSetup'

import Content from './modules/content'
import Pesquisa from './modules/pesquisa'
import SubMenu from './modules/subMenu'

import configUserAway from './modules/configUserAway'
import hamburguer from './modules/hamburguer'
import EditorJS from './modules/editorJS'
import contatoForm from './modules/contato'
import Lenis from 'lenis'
import gsap from 'gsap'
import ScrollSmooth from './modules/scrollSmooth'
import searchTypeSense from './modules/searchTypeSense'

// import SvgUse from "./utils/SvgUse";

// Initialize Lenis
/*
const lenis = new Lenis({
	autoRaf: true,
	duration: 0.75,
	easing: gsap.parseEase('power3.out')
});
*/

ScrollSmooth()

// Init
GLOBAL.DesignSystem = DesignSystem({})
register() // Swiper Web Component
hamburguer()
// Config
axiosConfig({ csrf: GLOBAL.context?.csrf }, axios)
UseMouseOrKeyboard()
ripple()
formErros()
animateSetup()
animaAoScroll()

Content()
Pesquisa()

// Pages
PageHome()
PageAArtista()
PageInstituto()
PageAcervo()
PageSingleAcervo()
PageContato()
PageApoio()

contatoForm()
configUserAway()
SubMenu()
EditorJS()

/*
PageDsSections()
*/
PageLogin()?.init()

// utils logs
logs()
searchTypeSense().init()

import { find, findAll } from "../utils/dom";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const SubMenu = () => {
	const Menu = find('[js-sub-menu]');
	if (!Menu) return;



	function resetOutline() {
		const itensA = Menu.querySelectorAll('a');
		itensA?.forEach((item) => {
			item.classList.remove('is_underline');
		})
	}

	// Registra o plugin ScrollTrigger
	gsap.registerPlugin(ScrollTrigger);


	const Sections = findAll('[js-section]');
	Sections.forEach((section) => {
		const idSecaoAtual = section.id;

		// Configura o ScrollTrigger para cada seção
		ScrollTrigger.create({
			trigger: section,              // Elemento que dispara o evento
			start: "top 20%",              // Quando o topo da seção atinge 50% da viewport
			end: "bottom 20%",             // Quando o fundo da seção atinge 50% da viewport
			onEnter: () => handleSectionEnter(section),
			onEnterBack: () => handleSectionEnter(section), // Ao voltar para a seção
			onLeave: () => resetOutline(), // Quando sair da seção
			onLeaveBack: () => resetOutline(),
		});
	});

	// Função para manipular a entrada em uma seção
	function handleSectionEnter(section) {
		const idSecaoAtual = section.id;
		const itemMenu = Menu.querySelector(`a[href='#${idSecaoAtual}']`);
		const isBlue = section.getAttribute('js-blue');
		const subMenuItem = itemMenu?.nextElementSibling;

		resetOutline();

		if (itemMenu) {
			itemMenu.classList.add('is_underline');
		}

		if (isBlue) {
			Menu.classList.add('is_blue');
		} else {
			Menu.classList.remove('is_blue');
		}

		if (subMenuItem) {
			subMenuItem.classList.add('is_open');
		} else {
			const submenu = document.querySelector('[js-submenu-itens]');
			if (submenu) submenu.classList.remove('is_open');
		}
	}

	/*
	const observer = new IntersectionObserver((entries, observe) => {
		entries.forEach((entry) => {
			console.log(entry);



			if (entry.isIntersecting) {
				const idSecaoAtual = entry.target.id;

				const itemMenu = Menu.querySelector(`a[href='#${idSecaoAtual}']`);
				const is_blue = entry.target.getAttribute('js-blue');
				const subMenuItem = itemMenu?.nextElementSibling;

				resetOutline();
				if (itemMenu) {
					itemMenu.classList.add("is_underline");

				}

				if (is_blue) {
					Menu.classList.add('is_blue');
				} else {
					Menu.classList.remove('is_blue');
				}

				if (subMenuItem) {
					subMenuItem.classList.add('is_open');
				} else {
					document.querySelector('[js-submenu-itens]').classList.remove('is_open')
				}
			}

		})
	}, {
		rootMargin: '100px 0px 0px 0px',
		threshold: 0,
	})


	Sections?.forEach((section) => {
		observer.observe(section)
	}); */


	window.addEventListener('scroll', () => {
		const scrollAtual = window.scrollY;

		if (scrollAtual > 180) {
			Menu.classList.add('is_scroll');
		}
		else {
			Menu.classList.remove('is_scroll');
		}
	})
}

export default SubMenu;